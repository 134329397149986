import React from "react";
import "./loadingOverlay.css";

const LoadingOverlay = ({ message }) => {
  return (
    <div className="flex flex-col justify-center items-center h-screen space-y-12 bg-white absolute top-0 w-full">
      <div className="spinner"></div>
      <div className="text-2xl text-center loader">
        {message || "Loading..."}
      </div>
    </div>
  );
};

export default LoadingOverlay;
