import React from "react";
import DayPass from "./DayPass";
import QRCode from "qrcode.react";
import { formatDate } from "../../services/helpers";

const DayPassQR = ({ center, data, validity }) => {
  console.log(formatDate(new Date()));
  return (
    <div className="shadow-lg px-16 md:py-24 py-4 md:w-3/4 w-full h-full bg-white rounded-lg flex flex-col justify-between items-center space-y-8">
      {data ? (
        <>
          <div>
            <h3 className="text-xl text-center">{data?.buildingName}</h3>
          </div>
          <div className="mt-2">
            <QRCode
              size={164}
              height={50}
              width={80}
              value={JSON.stringify(data)}
            />
          </div>
          <div>
            <h4 className="block text-xl text-center text-gray-500">
              Validity
            </h4>
            <h4 className="block text-xl text-center text-gray-500">
              {formatDate(data?.date)}
            </h4>
          </div>
        </>
      ) : (
        <h3 className="text-xl text-center text-red-500">
          There was an error generating the QR code
        </h3>
      )}
    </div>
  );
};

export default DayPassQR;
