import React, { useState, useEffect } from "react";
import "./DayPass.css";
import Header from "../../components/Header/Header";
import DayPassForm from "../DayPassForm/DayPassForm";
import DayPassQR from "./DayPassQR";
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";
import Alert from "../../components/Alert/Alert";

import axios from "axios";
import { constants } from "../../constants";
import { getMapLink, handleErrors } from "../../services/helpers";
import ReactGA from "react-ga";

const DayPass = () => {
  const [loading, setLoading] = useState(false);
  const [generated, setGenerated] = useState(false);
  const [qrData, setQrData] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    let timeout;
    if (error) {
      timeout = setTimeout(() => {
        setError(false);
        let val = timeout ? clearTimeout(timeout) : undefined;
      }, [3500]);
    }

    return () => (timeout ? clearTimeout(timeout) : undefined);
  }, [error]);

  const submitForm = async (data) => {
    setError(false);
    try {
      ReactGA.event({
        category: "Day Pass",
        action: "Booked a 2gethr Day Pass",
        label: "2gethr Day Pass",
      });
      setLoading(true);
      const response = await axios.post(
        `${constants.baseUrl}/dayPass/connect`,
        data
      );
      // console.log(response.data);
      setLoading(false);
      setQrData(response.data.dayPassDetails);
      setGenerated(true);
    } catch (e) {
      setError(handleErrors(e));
      setLoading(false);
      setGenerated(null);
    }
  };

  const display = () => {
    if (!generated) {
      return (
        <>
          {/* // Promotional Content */}
          <div className="lg:flex-1 content px-4">
            <h1 className="font-medium text-4xl md:text-5xl">
              Experience pandemic-proof productivity for a day
            </h1>
            <p className="md:text-xl text-sm text-gray-600 mt-4">
              We know you miss coming to work, so let us hook you up with our
              workspace
            </p>
          </div>
          {/* // Form */}
          <div className="lg:flex-1 flex justify-end overflow-auto p-0 md:p-8">
            <DayPassForm submitForm={submitForm} />
          </div>
        </>
      );
    }

    return (
      <>
        <div className="lg:flex-1 px-4 ">
          <h1 className="font-semibold text-4xl md:text-5xl">
            Your day pass is generated!
          </h1>
          <p className="md:text-xl text-sm text-gray-600 mt-4">
            We’ve emailed you the pass as well to your submitted email
          </p>
          <div className="mt-10">
            <a
              href={
                qrData?.buildingName ? getMapLink(qrData.buildingName) : "#"
              }
              target="_blank"
              rel="noopener noreferrer"
              className="uppercase tracking-widest underline font-bold text-base"
            >
              get directions
            </a>
          </div>
        </div>
        <div className="lg:flex-1 flex justify-center items-center overflow-auto p-0 md:p-8 mt-16 md:mt-0">
          <DayPassQR data={qrData} />
        </div>
      </>
    );
  };

  return (
    <>
      <div className="h-screen main bg-cover lg:px-12 px-8">
        {/* Header */}
        <div className="header">
          <Header />
        </div>
        <div className="flex lg:flex-row flex-col md:pb-0 pb-12">
          {display()}
        </div>
      </div>
      {loading && <LoadingOverlay message={"Generating a day pass for you"} />}
      {error && <Alert message={error} />}
    </>
  );
};

export default DayPass;
