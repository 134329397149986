import React, { useEffect } from "react";
import "./App.css";
import DayPass from "./containers/DayPass/DayPass";
import ReactGA from "react-ga";
import { constants } from "./constants";

ReactGA.initialize(constants.tracking_key);

function App() {
  return (
    <div className="App">
      <DayPass />
    </div>
  );
}

export default App;
