import React from "react";
import { constants } from "../../constants";
const Header = () => {
  return (
    <header className="flex items-center lg:space-x-8 justify-between lg:justify-start">
      <div>
        <a
          href="https://www.2gethr.xyz"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={constants.logo}
            alt="logo"
            className="h-24 w-24 m-0 p-0"
          ></img>
        </a>
      </div>
      {/* <div className="hover:bg-orange-300 py-2 px-4">
        <a
          href="www.google.com"
          className="text-sm md:text-base tracking-widest leading-4 uppercase font-bold outline-none"
        >
          home
        </a>
      </div> */}
      <div className="hover:bg-orange-300 py-2 px-4">
        <a
          href="https://www.2gethr.xyz/a-post-covid-19-workspace"
          className="text-sm md:text-base tracking-widest leading-4 font-bold outline-none uppercase"
        >
          covid-19 response
        </a>
      </div>
    </header>
  );
};

export default Header;
