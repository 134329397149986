import React from "react";
import DayPass from "../DayPass/DayPass";
import { useForm } from "react-hook-form";
import "./dayPassInput.css";
// import axios from "axios";

const DayPassForm = ({ submitForm }) => {
  const { register, handleSubmit, errors } = useForm();
  // console.log(errors);
  // const createDayPass = (data) => {
  //   console.log("submitted", data);
  // };

  return (
    <div className="shadow-lg px-8 py-12 w-full h-full bg-white rounded-lg">
      <form onSubmit={handleSubmit(submitForm)}>
        <div className="relative w-full">
          <label className="block text-sm leading-4">Full Name</label>
          <input
            className="block mt-1 p-3 w-full border border-gray-500 rounded placeholder-gray-500 focus:border-gray-800 outline-none"
            placeholder="Your Name"
            ref={register({ required: true })}
            name="username"
          />
          {errors?.username && (
            <span className="mt-4 block">
              <p className="text-red-400">
                {errors?.username?.message || "This field is required."}
              </p>
            </span>
          )}
        </div>

        <div className="mt-6">
          <label className="block text-sm leading-4">Email ID</label>
          <input
            className="block mt-1 p-3 w-full border border-gray-500 rounded placeholder-gray-500 focus:border-gray-800 outline-none"
            placeholder="Your Email ID"
            name="email"
            type="email"
            ref={register({ required: true })}
          />
          {errors?.email && (
            <span className="mt-4 block">
              <p className="text-red-400">
                {errors?.email?.message || "This field is required."}
              </p>
            </span>
          )}
        </div>

        <div className="mt-6">
          <label className="block text-sm leading-4">Phone Number</label>
          <input
            className="block mt-1 p-3 w-full border border-gray-500 rounded placeholder-gray-500 focus:border-gray-800 outline-none"
            placeholder="Your Phone Number"
            name="phoneNumber"
            ref={register({ required: true })}
          />
          {errors?.phoneNumber && (
            <span className="mt-4 block">
              <p className="text-red-400">
                {errors?.phoneNumber?.message || "This field is required."}
              </p>
            </span>
          )}
        </div>

        <div className="mt-6">
          <label className="block text-sm leading-4">Organization Name</label>
          <input
            className="block mt-1 p-3 w-full border border-gray-500 rounded placeholder-gray-500 focus:border-gray-800 outline-none"
            placeholder="Your Organisation Name"
            name="organisationName"
            ref={register({ required: true })}
          />
          {errors?.organisationName && (
            <span className="mt-4 block">
              <p className="text-red-400">
                {errors?.organisationName?.message || "This field is required."}
              </p>
            </span>
          )}
        </div>

        <div className="mt-6">
          <label className="block text-sm leading-4">Center of Choice</label>
          <select
            name="buildingName"
            ref={register}
            className="block mt-1 p-3 w-full border border-gray-500 rounded placeholder-gray-500 focus:border-gray-800 outline-none"
          >
            <option>2gethr@ORR</option>
            <option>2gethr@HSR</option>
          </select>
          {errors?.buildingName && (
            <span className="mt-4 block">
              <p className="text-red-400">
                {errors?.buildingName?.message || "This field is required."}
              </p>
            </span>
          )}
        </div>

        <div className="mt-6">
          <label className="block text-sm leading-4">Date</label>
          <input
            className="block mt-1 p-3 pr-4 w-full border border-gray-500 rounded placeholder-gray-500 focus:border-gray-800 outline-none"
            placeholder="Select your date"
            name="date"
            type="date"
            ref={register({ required: true })}
          />
          {errors?.date && (
            <span className="mt-4 block">
              <p className="text-red-400">
                {errors?.date?.message || "This field is required."}
              </p>
            </span>
          )}
        </div>

        <div className="mt-6">
          <button
            type="submit"
            className="w-full rounded uppercase text-center btn p-4 font-bold text-sm leading-4 tracking-widest outline-none transition-all duration-300 transform hover:shadow-xl"
          >
            gimme my pass
          </button>
        </div>
      </form>
      <p className="text-sm mt-6 text-center">
        Your pass will only be valid for the day of selection
      </p>
    </div>
  );
};

export default DayPassForm;
