import React from "react";
import "./Alert.css";

const Alert = ({ message, type }) => {
  return (
    <div className="bg-black px-4 py-4 alert rounded-md">
      <div className="text-base tracking-wide text-white">
        {message || "Something went wrong."}
      </div>
    </div>
  );
};

export default Alert;
