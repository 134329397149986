import { constants } from "../constants";
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const formatDate = (date_) => {
  let newDate = new Date(date_);
  let day = ("0" + newDate.getDate()).slice(-2);
  let month = monthNames[newDate.getMonth()];
  return `${day} ${month}`;
};

export const getMapLink = (str) => {
  str = str.toLowerCase();
  console.log(str);
  if (str.indexOf("orr") > -1) {
    return constants.map_orr;
  }

  return constants.map_hsr;
};

export const handleErrors = (error) => {
  if (error.response && error.response.status === 404) {
    return "Something went wrong";
  }

  if (error.response) {
    /*
     * The request was made and the server responded with a
     * status code that falls out of the range of 2xx
     */
    return (
      (error &&
        error.response &&
        error.response.data &&
        error.response.data.message) ||
      (error &&
        error.response &&
        error.response.data &&
        error.response.data.error)
    );
  }
  if (error.request) {
    /*
     * The request was made but no response was received, `error.request`
     * is an instance of XMLHttpRequest in the browser and an instance
     * of http.ClientRequest in Node.js
     */
    return "Something went wrong.";
  }
  // Something happened in setting up the request and triggered an Error
  return error.message;
};
