export const constants = {
  logo:
    "https://uploads-ssl.webflow.com/5d9f64ed50ffcbe80454e690/5dcecaff792b967d45b7f878_2gethr-logo.svg",
  dayPassBackground:
    "https://2gethrteam.s3.ap-south-1.amazonaws.com/2gethr-store-assets/bg.jpg",
  // baseUrl: "http://localhost:5000/api/v1",
  baseUrl: "https://stage.2gethr.xyz/api/v1",
  map_hsr:
    "https://www.google.com/maps/dir/12.9330733,77.6836484/2geth@hsr/@12.924295,77.6260493,13z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3bae1505e151f135:0xb7465605528b5258!2m2!1d77.6381003!2d12.9178187",
  map_orr:
    "https://www.google.com/maps/dir/12.9330733,77.6836484/2gethr+@+ORR,+Tower+-+B,+Outer+Ring+Road,+Sakra+World+Hospital,+Kariyammana+Agrahara,+Bellandur,+Bengaluru,+Karnataka/@12.9325472,77.6818987,17z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3bae1378e3916283:0xb193ef6af7222e8f!2m2!1d77.6835518!2d12.9332801",
  tracking_key: "UA-61847007-3",
};
